<template>
  <menubar />
  <main-container>
    <template v-slot:header>
      <router-link class="a-main-btn" :to="{ name: 'AddTest' }">
        Agregar
      </router-link>
      <div class="a-search-container">
        <InputText placeholder="Buscar" />
      </div>
    </template>

    <DataTable
      :value="tests"
      :paginator="true"
      :rows="8"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink"
      :filters="filters"
      :globalFilterFields="['name']"
    >
      <!-- Fields defined in data section -->
      <Column
        v-for="col of fields"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        sortable
      />

      <!-- Action buttons -->
      <Column headerStyle="width: 9rem">
        <template #body="slotProps">
          <div class="a-container-tab-btns">
            <button
              class="a-icon-tab-btn a-detail-btn"
              @click="detailUser(slotProps.data)"
            >
              <img :src="require('@/assets/icons/eye-white.svg')" />
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </main-container>

  <ConfirmDialog />

  <Toast />
</template>

<script>
import Menubar from "@/components/navigation/Top.vue";
import MainContainer from "@/components/secure/MainContainer.vue";
import { FilterMatchMode } from "primevue/api";
export default {
  components: {
    Menubar,
    MainContainer,
  },
  data() {
    return {
      tests: [],
      fields: [
        {
          field: "id",
          header: "No. de prueba",
        },
        {
          field: "name",
          header: "Nombre",
        },
        {
          field: "questions",
          header: "No. de preguntas",
        },
        {
          field: "created_at",
          header: "Fecha de creación",
        },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      settings: {
        detailModal: false,
        user: null,
      },
    };
  },
  methods: {
    getTests() {
      this.$store
        .dispatch("test/GET_TEST_LIST")
        .then((response) => {
          this.tests = response;
        })
        .catch((error) => {
          if (error == "token") {
            this.getTests();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error,
              life: 3000,
            });
          }
        });
    },
    detailUser(item) {
      this.settings.user = item;
      this.settings.detailModal = true;
    },
    deleteUser(item) {
      let message = `¿Estás seguro de eliminar el usuario ${item.username}?`;
      this.$confirm.require({
        message: message,
        header: "Eliminar usuario",
        acceptLabel: "Aceptar",
        acceptClass: "p-button-danger",
        rejectLabel: "Cancelar",
        accept: () => {
          //callback to execute when user confirms the action
          this.$store
            .dispatch("tests/DELETE_USER", item)
            .then(() => this.getTests)
            .catch((error) => {
              if (error == "token") {
                this.deleteUser(item);
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Ha ocurrido un error",
                  detail: error,
                  life: 3000,
                });
              }
            });
        },
      });
    },
  },
  mounted() {
    this.getTests();
  },
};
</script>

<style>
.a-content {
  background: white;
}
</style>
